/*
 * Filename: application/frontend/src/authConfig.js
 * Description: authentication configuration
 * Author: Aires Marques
 * History:
 + 2023-11-23 - documented
 * Notes:
*/

export const msalConfig = {
    auth: {
      clientId: "$APPLICATION_CLIENT_ID",
      authority: "https://login.microsoftonline.com/c567fe94-f430-4773-9330-9dc2f3334913",
      redirectUri: "https://black-coast-08bdb8c03.5.azurestaticapps.net"
      // For Local development - uncomment the line below and comment the line above
      // redirectUri: "http://localhost:3000"
    },
    cache: {
      // This configures where the cache will be stored
      cacheLocation: "sessionStorage", 
      
      // Set this to "true" if having issues on IE11 or Edge
      storeAuthStateInCookie: false, 
    }
};
  
// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"]
};


// Reading from environment variables
const REACT_APP_BACKEND_URL = `https://digipay2-backend.azurewebsites.net/api/`;


export const backend = {
  url: REACT_APP_BACKEND_URL,
  ping: REACT_APP_BACKEND_URL + "ping?details=1",
  
  // function POST payrequest ping
  url_post_payrequest: REACT_APP_BACKEND_URL + "v6/payrequest/mbway",
  ping_post_payrequest: REACT_APP_BACKEND_URL + "v6/payrequest/mbway?ping=1",

  // function GET payrequest ping
  url_get_payrequest: REACT_APP_BACKEND_URL + "v4/payrequest/mbway",
  ping_get_payrequest: REACT_APP_BACKEND_URL + "v4/payrequest/mbway?ping=1",

  // function GET config stripe
  url_get_payrequest_config_stripe: REACT_APP_BACKEND_URL + "payrequest/stripe/config",
  ping_get_payrequest_config_stripe: REACT_APP_BACKEND_URL + "payrequest/stripe/config?ping=1",

  // function POST payrequest stripe
  url_post_payrequest_stripe: REACT_APP_BACKEND_URL + "v4/payrequest/stripe/create-payment-intent",
  ping_post_payrequest_stripe: REACT_APP_BACKEND_URL + "v4/payrequest/stripe/create-payment-intent?ping=1",

  // function POST chat/chatwoot
  url_post_chatbot: REACT_APP_BACKEND_URL + "chat/chatwoot",
  ping_post_chatbot: REACT_APP_BACKEND_URL + "chat/chatwoot?ping=1",

  // function GET
  url_get_aux_validate_email: REACT_APP_BACKEND_URL + "aux/validate/email",
  ping_get_aux_validate_email: REACT_APP_BACKEND_URL + "aux/validate/email?ping=1",

};



export const backend_services = {
  // function ping
  url: 'https:///api/',
  ping : 'https:///api/TODO',

  // function ... ping
  // ON BACKEND - TODO - Add ping message to queue as well
  url_paycallback: 'https:///api/TODO',
  ping_paycallback : 'https:///api/TODO',
}


export const app_vars = {
url: "https://digipay2stdata.blob.core.windows.net/public-configuration/app_vars.json",
};

export const app_location_data = {
  url: "https://digipay2stdata.blob.core.windows.net/public-configuration/location_data.json",
};


export const app_location_definition = {
  url: "https://digipay2stdata.blob.core.windows.net/public-configuration/locations.json",
};



  
  // Not used at the moment
  //export const apiRequestFuncTemplateNode = {
  // url: 'https://digipay2-backend.azurewebsites.net/api/funcTemplateNode',
  //  scopes: ['api:///user_impersonation']    
  //}